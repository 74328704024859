.backdrop {
  position: fixed;
  top: -10px;
  right: -10px;
  bottom: 0;
  left: 0;
  transition: background ease 150ms;
  pointer-events: auto;
  z-index: 1600;
  background: rgba(0, 0, 0, 0.5);
}
